<template>
  <page-container title="用户分布" :breadcrumb="breadcrumb">
    <div class="dept-container">
      <div class="box-container" style="margin-top: 24px; width: 320px; padding-bottom: 24px;">
        <div class="box-container-inner" style="height: calc(100vh - 200px); overflow: auto;">
          <div class="tree-top">
            <a-input-search style="margin-bottom: 8px" placeholder="搜索" enter-button="搜索" @search="onSearch" @change="onChange"/>
          </div>
          <a-tree
              :expanded-keys="expandedKeys"
              :auto-expand-parent="autoExpandParent"
              :selected-keys.sync="selectedKeys"
              :replace-fields="{children:'children', title:'userdepname', key:'userdepid'}"
              :tree-data="userDeptTree"
              @select="selectDept"
              @expand="onExpand"
          >
            <template slot="title" slot-scope="{ userdepname,userdepid }">
              <span v-if="userdepname.indexOf(searchValue) > -1" :id="userdepid">
                {{ userdepname.substr(0, userdepname.indexOf(searchValue)) }}
                <span style="color: red;font-weight: bold;">{{ searchValue }}</span>
                {{ userdepname.substr(userdepname.indexOf(searchValue) + searchValue.length) }}
              </span>
              <span v-else>{{ userdepname }}</span>
            </template>
          </a-tree>
        </div>
      </div>
      <div class="box-container" style="margin-top: 24px; flex: 1; padding-bottom: 24px; padding-left: 0;">
        <div class="box-container-inner" style="height: calc(100vh - 200px);">
          <people-management :showTitle="false" :userDepId="userdepid"></people-management>
        </div>
      </div>
    </div>
  </page-container>
</template>

<script>
import {getCache} from 'U'
import {getTreeOfUserDepHasUser} from "A/jcgn";
import PeopleListModal from "V/jcgn/userDept/PeopleListModal";
import PeopleManagement from "V/xtpz/yhgl/peopleManagement";

const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.userdepid === key)) {
        parentKey = node.userdepid;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};
export default {
  name: "peopleDistributed",
  components: {
    PeopleListModal,
    PeopleManagement,
  },
  data(){
    return{
      breadcrumb: [
        {
          name: '系统配置',
          path: ''
        },
        {
          name: '用户分布',
          path: ''
        },
      ],
      selectedKeys: [],
      userDeptTree: [],
      expandedKeys: [],
      dataList:[],
      searchValue: '',
      autoExpandParent: true,
      userdepid:'',
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
    this.init();
  },
  methods:{
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onChange(e) {
      const value = e.target.value;
      const expandedKeys = this.dataList
          .map(item => {
            if (item.userdepname.indexOf(value) > -1) {
              return getParentKey(item.userdepid, this.userDeptTree);
            }
            return null;
          })
          .filter((item, i, self) => item && self.indexOf(item) === i);
      Object.assign(this, {
        expandedKeys,
        searchValue: value,
        autoExpandParent: true,
      });
    },
    onSearch(value) {
      this.dataList
          .map(item => {
            if (item.userdepname.indexOf(value) > -1) {
              this.$nextTick(() => {
                document.getElementById(item.userdepid).scrollIntoView(true);
              })
              return getParentKey(item.userdepid, this.userDeptTree);
            }
            return null;
          })
    },

    init() {
      let userDepLists = getCache('userDepLists', true);
      if(userDepLists) {
        this.userDeptTree = userDepLists.userDepList;
      }else{
        this.getDeptTree();
      }
      this.generateList(this.userDeptTree)
    },
    getDeptTree(parentid='') {
      let params = {
        parentid
      };
      this.showLoading();
      getTreeOfUserDepHasUser(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.userDeptTree=res.item
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    selectDept(item) {
      this.userdepid=item[0]
    },
    generateList(data){
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const id = node.userdepid;
        const name = node.userdepname;
        this.dataList.push({ userdepid:id, userdepname: name });
        if (node.children) {
          this.generateList(node.children);
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.dept-container {
  display: flex;
}
.select-drop-down-platform {
  width: 240px;
}
</style>